<template>
  <b-card>
    <div class="d-flex align-items-center justify-content-between table-header">
      <div class="d-flex align-items-center">
        <label class="inline-label">Show</label>
        <b-form-select v-model="perPage" :options="perPageOptions" @change="handlePerPage" />
      </div>

      <div class="d-flex">
        <b-button variant="danger" @click="resetSearch()" class="ml-1">
          <feather-icon icon="RefreshCcwIcon" />
        </b-button>

        <search :add-button="true" @addButtonAction="$router.push({ name: 'event/form' })"
          @handleAdvanceSearch="handleSearch" @resetAdvanceSearch="handleReset" />
      </div>
    </div>

    <b-table class="border mt-1" :fields="tableField" :items="tableData" show-empty responsive hover :busy="loading">
      <template #table-busy>
        <div class="text-center text-danger my-2" style="padding:50px 0">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading...</strong>
        </div>
      </template>

      <template #cell(action)="data">
        <b-button-group>
          <b-button variant="flat-dark" @click.prevent="handleEdit(data.item)" v-b-tooltip.hover title="Edit">
            <feather-icon size="20" icon="EditIcon" />
          </b-button>

          <b-button variant="flat-dark" @click.prevent="handleRemove(data.item)" v-b-tooltip.hover title="Delete">
            <feather-icon size="20" icon="TrashIcon" />
          </b-button>
        </b-button-group>
      </template>

      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(keterangan)="data">
        <span class="text-nowrap">{{ data.item.keterangan }}</span>
      </template>

      <template #cell(tgl_awal)="data">
        <span class="text-nowrap">{{ moment(data.value).format('DD-MM-YYYY') }}</span>
      </template>

      <template #cell(tgl_akhir)="data">
        <span class="text-nowrap">{{ moment(data.value).format('DD-MM-YYYY') }}</span>
      </template>

      <template #cell(charge)="data">
        <div class="text-right btn btn-sm btn-danger">
          {{ convertToIDR(data.item.charge) }}
        </div>
      </template>

      <template #empty="scope">
        <div class="text-center my-75">
          <b-img :src="ClipboardsSecondary" />
          <div class="text-secondary font-small-3 mt-75">
            Tidak ada data
          </div>
        </div>
      </template>
    </b-table>

    <div class="d-flex justify-content-between flex-wrap">
      <label class="inline-label">Count : {{ tableData.length }} {{ tableData.length > 1 ? 'Items' : 'Item' }}</label>
      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" aria-controls="my-table" />
    </div>
  </b-card>
</template>

<script>
import { BFormSelect } from 'bootstrap-vue'
import { convertToIDR } from '@/@core/utils/utils'
import ClipboardsSecondary from '@/assets/images/svg/clipboards-secondary.svg'
import Search from './Search.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Swal from 'sweetalert2'
import moment from 'moment'

export default {
  components: {
    BFormSelect,
    Search,
  },
  data() {
    return {
      ClipboardsSecondary,
      perPage: 10,
      currentPage: 1,
      rows: 0,
      searchVal: '',
      perPageOptions: [
        { value: 10, text: '10' },
        { value: 25, text: '25' },
        { value: 50, text: '50' },
        { value: 100, text: '100' },
      ],
      tableField: [
        { key: 'no', label: 'No', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
        { key: 'keterangan', label: 'Nama Event', thClass: 'text-center' },
        { key: 'tgl_awal', label: 'Tanggal Awal', thClass: 'text-center' },
        { key: 'tgl_akhir', label: 'Tanggal Akhir', thClass: 'text-center' },
        { key: 'charge', label: 'Charge', thClass: 'text-center', class: 'text-right' },
        { key: 'minimalorder', label: 'Minimal Order', thClass: 'text-center', class: 'text-center' },
        { key: 'action', label: 'Action', class: 'text-center', thClass: 'text-center', thStyle: { width: "5%" } },
      ],
      tableData: [],
      loading: false,
    }
  },
  watch: {
    currentPage(x, y) {
      if (x !== y) {
        this.getListData()
      }
    },
  },

  mounted() {
    this.getListData()
  },
  methods: {
    convertToIDR,
    moment,

    handleSearch(val) {
      this.searchVal = val
      this.getListData()
    },

    handleReset(val) {
      this.searchVal = val
      this.getListData();
    },

    handlePerPage(val) {
      this.perPage = val
      this.getListData()
    },

    getListData() {
      this.loading = true;
      const params = {
        namaevent: '',
        page: this.currentPage,
        perPage: this.perPage,
        keyword: this.searchVal.Keyword ?? '',
      };

      this.$http.get('/api/event/list', { params: params }).then(res => {
        this.loading = false;
        this.tableData = res.data.data;
        this.rows = res.data.maxPage * this.perPage;

        if (this.searchVal != '') {
          if (res.data.data.length > 0) {
            this.$toast({
              component: ToastificationContent, position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'Pencarian data berhasil ditemukan',
              },
            });
          } else {
            this.$toast({
              component: ToastificationContent, position: 'top-right',
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                variant: 'warning',
                text: 'Pencarian data tidak ditemukan',
              },
            });
          }
        }
      }).catch(e => {
        this.loading = false;
        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: 'Gagal mendapatkan data' + e,
          },
        });
        this.tableData = [];
        this.rows = 0;
      });
    },

    resetSearch() {
      this.searchVal = ''
      this.getListData()
    },

    handleEdit(item) {
      this.$router.push(`/event/form/${item.kodeevent}`);
    },

    async handleRemove(item) {
      const isConfirmed = await Swal.fire({
        title: 'Konfirmasi',
        text: `Apakah Anda yakin ingin menghapus ${item.keterangan}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, Hapus!',
        cancelButtonText: 'Batal',
      });

      if (!isConfirmed.value) {
        return;
      }

      const data = {
        kodeevent: item.kodeevent
      }

      this.$http.post('/api/event/delete', data).then(res => {
        if (!res.data.status) {
          this.$toast({
            component: ToastificationContent, position: 'top-right',
            props: {
              title: 'Notification',
              icon: 'SlashIcon',
              variant: 'danger',
              text: res.data.message,
            },
          });
          return;
        }

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'CheckIcon',
            variant: 'success',
            text: '👋Berhasil menghapus data',
          },
        });

        this.getListData()

      }).catch(e => {
        // Menampilkan pesan kesalahan lebih spesifik
        const errorMessage = e.response ? e.response.data.message : 'Gagal menghapus data';

        this.$toast({
          component: ToastificationContent, position: 'top-right',
          props: {
            title: 'Notification',
            icon: 'SlashIcon',
            variant: 'danger',
            text: errorMessage,
          },
        });
      });
    },
  }
}
</script>
